import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { sendLoginEmail } from "../../../utilses/email"
import {
  CAR_AND_LISCENSE_CHOICES,
  EDUCATION_CHOICES,
  EMPLOYMENT_TYPE,
  EMPLOYMENT_TYPE_CHOICES,
  EXPECTED_SALARY_CHOICES,
  GENDER_CHOICES,
  JOb_TYPE,
  MARTIAL_STATUS_CHOICES,
  NOTICE_PERIOD_CHOICES,
  PREFERRED_LOCATION_CHOICES,
  SKILLS_CHOICES,
  VISA_STATUS_CHOICES,
} from "../../../data";
import toast from "react-hot-toast";
import TermAndConditions from "../termAndConditions";
import { useAppContext } from "../../../context/appContext";
import { in200s } from "../../../helper";
//   import { in200s, in400s } from "../../helper/index";

const AllServices = ({ id, title, allServicesModal, setAllServicesModal }) => {
  const [jobHuntingCondition, setJobHuntingCondition] = useState(false);
  const [laoding, setLoading] = useState(false);
  const [conditionPopup, setConditionPopup] = useState(false);
  const [otherPreferredLocation, setOtherPreferredLocation] = useState(false);
  const { applyForAll } = useAppContext();

  const [inputValue, setInputValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    date_of_birth: "",
    current_location: "",
    expected_salary: "",
    years_of_experience: "",
    notice_preference: "",
    education: "",
    notice_period: "",
    preferred_location: "",
    other_location: "",
    visa_status: "",
    car_and_liscense: "",
    martial_status: "",
    address: "",
    country: "",
    postal_code: "",
    gender: "",
    skills: "",
    linkedin_username: "",
    linkedin_password: "",
    resume: "",
    cover_letter: "",
    job_type: "",
    employment_type: "",
    preferred_title: "",
  });
  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setInputValue({
  //       ...inputValue,
  //       [name]: value,
  //     });
  //   };

  const handleChange = (e) => {
    if (e.target.name === "preferred_location") {
      if (
        e.target.name === "preferred_location" &&
        e.target.value === "Current Area only"
      ) {
        setOtherPreferredLocation(false);
      }
      if (
        e.target.name === "preferred_location" &&
        e.target.value === "All over the UK"
      ) {
        setOtherPreferredLocation(false);
      }
      if (e.target.name === "preferred_location" && e.target.value === "") {
        setOtherPreferredLocation(false);
      }
      if (e.target.value === "Other") {
        setOtherPreferredLocation(true);
        if (e.target.name === "other_location") {
          setInputValue({
            ...inputValue,
            preferred_location:
              e.target.value === "Other" ? "" : e.target.value,
            [e.target.name]: e.target.value,
          });
        }
      }
    }
    if (e.target.name === "resume" || e.target.name === "cover_letter") {
      // If it's a file input, set the file to state
      setInputValue({ ...inputValue, [e.target.name]: e.target.files[0] });
    } else {
      setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!jobHuntingCondition) {
      toast.error("Please agree to the terms and conditions", {
        // duration: 80000,
        // zIndex: 99999999999,
      });
      return;
    }
    if (
      !inputValue.first_name ||
      !inputValue.last_name ||
      !inputValue.email ||
      !inputValue.phone_no ||
      !inputValue.date_of_birth ||
      !inputValue.current_location ||
      !inputValue.expected_salary ||
      !inputValue.notice_period ||
      !inputValue.education ||
      !inputValue.notice_period ||
      !inputValue.preferred_location ||
      !inputValue.visa_status ||
      !inputValue.car_and_liscense ||
      !inputValue.martial_status ||
      !inputValue.address ||
      !inputValue.country ||
      !inputValue.postal_code ||
      !inputValue.gender ||
      !inputValue.skills ||
      // !inputValue.linkedin_username ||
      // !inputValue.linkedin_password ||
      !inputValue.resume ||
      !inputValue.cover_letter ||
      !inputValue.job_type ||
      !inputValue.employment_type ||
      !inputValue.preferred_title
    ) {
      toast.error("Please fill all the required fields");
      return;
    }

    if (
      inputValue.preferred_location === "Other" &&
      !inputValue.other_location
    ) {
      toast.error("Please fill all the required fields");
      return;
    }

    setLoading(true);


    await sendLoginEmail(inputValue)
    .then(response => console.log('Email sent successfully:', response))
    .catch(error => console.error('Error sending email:', error));


    const data = {
      first_name: inputValue.first_name,
      last_name: inputValue.last_name,
      email: inputValue.email,
      phone_no: inputValue.phone_no,
      date_of_birth: inputValue.date_of_birth,
      current_location: inputValue.current_location,
      expected_salary: inputValue.expected_salary,
      years_of_experience: inputValue.years_of_experience,
      // notice_preference: inputValue.notice_preference,
      education: inputValue.education,
      notice_period: `${inputValue.notice_period} ${inputValue.notice_preference}`,
      preferred_location: inputValue.preferred_location,
      other_location: inputValue.other_location,
      visa_status: inputValue.visa_status,
      car_and_liscense: inputValue.car_and_liscense,
      martial_status: inputValue.martial_status,
      address: inputValue.address,
      country: inputValue.country,
      postal_code: inputValue.postal_code,
      cover_letter: inputValue.cover_letter,
      employment_type: inputValue.employment_type,
      gender: inputValue.gender,
      job_type: inputValue.job_type,
      linkedin_username: inputValue.linkedin_username,
      linkedin_password: inputValue.linkedin_password,
      resume: inputValue.resume,
      skills: inputValue.skills,
      preferred_title: inputValue.preferred_title,
      client_type:"All Services"
    };

    const response = await applyForAll(data);
    console.log(response);
    if (in200s(response.status)) {
      toast.success("Application submitted successfully");
      setInputValue({
        first_name: "",
        last_name: "",
        email: "",
        phone_no: "",
        date_of_birth: "",
        current_location: "",
        expected_salary: "",
        years_of_experience: "",
        notice_preference: "",
        education: "",
        notice_period: "",
        preferred_location: "",
        other_location: "",
        visa_status: "",
        car_and_liscense: "",
        martial_status: "",
        address: "",
        country: "",
        postal_code: "",
        gender: "",
        skills: "",
        linkedin_username: "",
        linkedin_password: "",
        resume: "",
        cover_letter: "",
        job_type: "",
        employment_type: "",
        preferred_title: "",
        client_type:"All Services"
      });

      setAllServicesModal(!allServicesModal);
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
    setJobHuntingCondition(false);

    // setLoading(true);
    // setJobHuntingCondition(false);
  };

  
  return (
    <>
      <div onClick={()=>setAllServicesModal(val=>!val)} id={id} className="modal-container">
        <div onClick={(e) => e.stopPropagation()}  className="modal-box-lg !pt-0">
          <div className="modal-close">
            {/* if there is a button, it will close the modal */}
            <button
              className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-gray-200 hover:bg-gray-300 text-white"
              onClick={() => {
                setJobHuntingCondition(false);
                setAllServicesModal(!allServicesModal);
              }}
            >
              <IoClose />
            </button>
          </div>
          <div className="flex flex-col md:gap-2 gap-1">
            <h3 className="pop-up-heading">{title}</h3>
            <form
              className="grid md:grid-cols-2  grid-cols-1 md:gap-2 gap-1"
              onSubmit={handlesubmit}
              encType="multipart/form-data"
            >
              {/* first name */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="first_name" className="label-text">
                  first name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="input-field"
                  value={inputValue.first_name}
                  onChange={handleChange}
                />
              </div>
              {/* first name */}

              {/* last name */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="last_name" className="label-text">
                  last name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="last_name"
                  value={inputValue.last_name}
                  onChange={handleChange}
                  name="last_name"
                  className="input-field"
                />
              </div>
              {/* last name */}

              {/* email */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="email" className="label-text">
                  email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={inputValue.email}
                  onChange={handleChange}
                  className="input-field"
                />
              </div>
              {/* email */}

              {/* phone_no */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="phone_no" className="label-text">
                  phone/mobile<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="phone_no"
                  value={inputValue.phone_no}
                  onChange={handleChange}
                  name="phone_no"
                  className="input-field"
                />
              </div>
              {/* phone_no */}

              {/* date_of_birth */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="date_of_birth" className="label-text">
                  Date of birth<span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  value={inputValue.date_of_birth}
                  onChange={handleChange}
                  className="input-field"
                />
              </div>
              {/* date_of_birth */}

              {/* current_location */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="current_location" className="label-text">
                  current location
                </label>
                <input
                  type="text"
                  id="current_location"
                  name="current_location"
                  className="input-field"
                  value={inputValue.current_location}
                  onChange={handleChange}
                />
              </div>
              {/* current_location */}

              {/* expected_salary */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="expected_salary" className="label-text">
                  expected salary range<span className="text-red-500">*</span>
                </label>

                <select
                  name="expected_salary"
                  id="expected_salary"
                  value={inputValue.expected_salary}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {EXPECTED_SALARY_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
              {/* expected_salary */}

              {/* years_of_experience */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="years_of_experience" className="label-text">
                  year of experience
                </label>
                <input
                  type="number"
                  id="years_of_experience"
                  name="years_of_experience"
                  value={inputValue.years_of_experience}
                  onChange={handleChange}
                  className="input-field"
                />
              </div>
              {/* years_of_experience */}

              {/* education */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="education" className="label-text">
                  education<span className="text-red-500">*</span>
                </label>
                <select
                  name="education"
                  id="education"
                  value={inputValue.education}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {EDUCATION_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                  {/* <option value="intermidiate">Intermidiate</option>
                <option value="Bachelor Degree">Bachelor Degree</option>
                <option value="Master">Master</option>
                <option value="PHD">PHD</option> */}
                </select>
              </div>
              {/* education */}

              {/* notice_period */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="notice_period" className="label-text">
                  notice period<span className="text-red-500">*</span>
                </label>
                <div className="flex md:gap-0.8 gap-0.5">
                  <input
                    type="number"
                    value={inputValue.notice_period}
                    onChange={handleChange}
                    id="notice_period"
                    name="notice_period"
                    className="input-field w-[70%]"
                  />
                  <select
                    name="notice_preference"
                    id="notice_preference"
                    value={inputValue.notice_preference}
                    onChange={handleChange}
                    className="input-field w-[30%]"
                  >
                    <option value={""}>-select-</option>
                    {NOTICE_PERIOD_CHOICES.map((choice) => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* notice_period */}

              {/* preferred_location */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="preferred_location" className="label-text">
                  preferred locations for the job
                </label>
                <div className="flex md:gap-0.8 gap-0.5">
                  <select
                    name="preferred_location"
                    id="preferred_location"
                    value={inputValue.preferred_location}
                    onChange={handleChange}
                    className={`input-field ${
                      otherPreferredLocation ? `w-1/2` : `w-full`
                    }`}
                  >
                    <option value={""}>-select-</option>
                    {PREFERRED_LOCATION_CHOICES.map((choice) => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </select>
                  <div className={`${otherPreferredLocation ? `w-1/2` : ``}`}>
                    {inputValue.preferred_location === "Other" && (
                      <input
                        type="text"
                        id="other_location"
                        name="other_location"
                        value={inputValue.other_location}
                        onChange={handleChange}
                        className="input-field w-full"
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* preferred_location */}

              {/* visa_status */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="visa_status" className="label-text">
                  visa status
                </label>
                <select
                  name="visa_status"
                  id="visa_status"
                  value={inputValue.visa_status}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {VISA_STATUS_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                  {/* <option value="remote">option 1</option>
                <option value="Onsite">option 2</option> */}
                </select>
              </div>
              {/* visa_status */}

              {/* car_and_liscense */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="car_and_liscense" className="label-text">
                  Car and License
                </label>
                <select
                  name="car_and_liscense"
                  id="car_and_liscense"
                  className="input-field"
                  value={inputValue.car_and_liscense}
                  onChange={handleChange}
                >
                  <option value={""}>-select-</option>
                  {CAR_AND_LISCENSE_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                  {/* <option value="remote">Yes</option>
                <option value="Onsite">No</option> */}
                </select>
              </div>
              {/* car_and_liscense */}

              {/* martial_status */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="martial_status" className="label-text">
                  marital status
                </label>
                <select
                  name="martial_status"
                  id="martial_status"
                  value={inputValue.martial_status}
                  className="input-field"
                  onChange={handleChange}
                >
                  <option value={""}>-select-</option>
                  {MARTIAL_STATUS_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
              {/* martial_status */}

              {/* gender  */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="gender" className="label-text">
                  gender<span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  id="gender"
                  value={inputValue.gender}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {GENDER_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                  {/* <option value="male">Male</option>
                <option value="female">female</option> */}
                </select>
              </div>
              {/* gender  */}

              {/* address */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="address" className="label-text">
                  address<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  className="input-field"
                  value={inputValue.address}
                  onChange={handleChange}
                />
              </div>
              {/* address */}

              {/* country */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="country" className="label-text">
                  country<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  className="input-field"
                  value={inputValue.country}
                  onChange={handleChange}
                />
              </div>
              {/* country */}

              {/* Postal Code */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="postal_code" className="label-text">
                  Postal Code<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="postal_code"
                  name="postal_code"
                  className="input-field"
                  value={inputValue.postal_code}
                  onChange={handleChange}
                />
              </div>
              {/* Postal Code */}

              {/* Current Title */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="skills" className="label-text">
                  Current Title<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="skills"
                  name="skills"
                  className="input-field"
                  value={inputValue.skills}
                  onChange={handleChange}
                />

                {/* <select
                  name="skills"
                  id="skills"
                  value={inputValue.skills}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {SKILLS_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select> */}
              </div>
              {/* Current Title */}

              {/* Preferred Title */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="preferred_title" className="label-text">
                  Preferred Title<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="preferred_title"
                  name="preferred_title"
                  className="input-field"
                  value={inputValue.preferred_title}
                  onChange={handleChange}
                />

                {/* <select
                  name="preferred_title"
                  id="preferred_title"
                  value={inputValue.preferred_title}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {SKILLS_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select> */}
              </div>
              {/* Preferred Title */}

              {/* job_type */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="job_type" className="label-text">
                  Job Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="job_type"
                  id="job_type"
                  value={inputValue.job_type}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {JOb_TYPE.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
              {/* job_type */}

              {/* Employment Type */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="employment_type" className="label-text">
                  Employment Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="employment_type"
                  id="employment_type"
                  value={inputValue.employment_type}
                  onChange={handleChange}
                  className="input-field"
                >
                  <option value={""}>-select-</option>
                  {EMPLOYMENT_TYPE_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
              {/* Employment Type */}

              {/* linkedin_username */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="linkedin_username" className="label-text">
                  linkedin credentials email or username{" "}
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  id="linkedin_username"
                  name="linkedin_username"
                  value={inputValue.linkedin_username}
                  onChange={handleChange}
                  className="input-field"
                />
              </div>
              {/* linkedin_username */}

              {/* linkedin_password */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="linkedin_password" className="label-text">
                  linkedin credentials password{" "}
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  id="linkedin_password"
                  name="linkedin_password"
                  className="input-field"
                  value={inputValue.linkedin_password}
                  onChange={handleChange}
                />
              </div>
              {/* linkedin_password */}

              {/* resume */}
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="resume" className="label-text">
                  upload CV/Resume
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  className="input-field"
                  // value={inputValue.resume}
                  onChange={handleChange}
                />
              </div>
              {/* resume */}

              {/* cover_letter */}

              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="cover_letter" className="label-text">
                  upload cover letter<span className="text-red-500">*</span>
                </label>
                <input
                  // value={inputValue.cover_letter}
                  onChange={handleChange}
                  type="file"
                  id="cover_letter"
                  name="cover_letter"
                  className="input-field"
                />
              </div>
              {/* cover_letter */}
              <div className=" md:col-span-2   flex item-start justify-start">
                <div className="flex items-center md:gap-0.8 gap-0.5">
                  <input
                    type="checkbox"
                    className="checkbox border-[1px] focus:ring-1 focsu:ring-primary checkbox-xs rounded-sm border-primary [--chkbg:theme('colors.primary')]"
                    name="termAndConditions"
                    id="termAndConditions"
                    checked={jobHuntingCondition}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setJobHuntingCondition(!jobHuntingCondition);

                        setConditionPopup(!conditionPopup);
                      }
                      setJobHuntingCondition(!jobHuntingCondition);
                    }}
                  />
                  <label
                    htmlFor="termAndConditions"
                    className="text-sm text-gray-500"
                  >
                    I agree to the{" "}
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        setJobHuntingCondition(true);
                        setConditionPopup(!conditionPopup);
                      }}
                    >
                      terms and conditions
                    </span>
                  </label>
                </div>
              </div>

              <div className=" md:col-span-2   flex item-center">
                <button
                  className="lg:inline-flex items-center border bg-primary lg:hover:bg-transparent lg:hover:text-black transition-all text-white border-primary  md:px-[2rem] px-[1.5rem] md:py-[1rem] py-[0.8rem] text-xl capitalize text-wrap rounded-lg  text-center "
                  type="submit" 
                >
                  {laoding ? "submiting..." : "submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {conditionPopup && (
        <TermAndConditions
          id="term_and_conditions"
          conditionPopup={conditionPopup}
          setConditionPopup={setConditionPopup}
        />
      )}
    </>
  );
};

export default AllServices;
