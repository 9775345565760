import React, { useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { scrollToTop } from "../../utils/helper";

const libraries = ["places"];
const center = {
	lat: 7.2905715, // default latitude
	lng: 80.6337262, // default longitude
};
const mapContainerStyle = {
	width: "100%",
	height: "100%",
};
const ContactUs = () => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: "AIzaSyDJGVtH-eSYv3yJRHTxeluhfi5BJGlnFHs",
		libraries,
	});
	// useEffect(() => {
	// 	scrollToTop();
	// }, []);
	if (loadError) {
		return <div>Error loading maps</div>;
	}

	if (!isLoaded) {
		return <div>Loading maps</div>;
	}
	return (
		<>
			<div className="bg-about-banner bg-no-repeat bg-center bg-cover section-y-pad">
				<div className="container relative">
					<div className="max-w-[800px] w-full m-auto section-y-pad">
						<div className="f-col gap text-white lg:gap-[40px] md:gap-[30px] gap-4 ">
							<h1 className="text-center lg:text-7xl md:text-6xl text-5xl leading-tight font-semibold">
								Contact Us
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="section-x-pad">
				<div className="lg:h-[312px] md:h-[472px] h-[252px] container lg:-mt-[6rem] md:-mt-[5rem] -mt-[4rem] p-6 w-full overflow-hidden md:shadow-lg shadow-md md:rounded-xl rounded-lg bg-white">
					<div className="flex lg:gap-10 gap-5 min-w-[340px]">
						<div className="f-col gap">
							<h3 className="md:text-[1.4rem] text-xl  text-black  leading-normal font-bold">
								Corporate Headquarters
							</h3>
							<span className="md:text-[1.2rem] text-[1rem]  text-black  leading-normal font-medium">
								Mon-Fri from 9am to 5pm.
							</span>
							<ul className="f-col gap">
								<li className="para">S605DD, Rotherham, United Kingdom</li>
							</ul>
						</div>
						<div className="w-full">
							{" "}
							<GoogleMap
								mapContainerStyle={mapContainerStyle}
								zoom={10}
								center={center}
							>
								<Marker position={center} />
							</GoogleMap>{" "}
						</div>
					</div>
				</div>
			</div>

			<div className="relative before:absolute before::content-[''] origin-top-right section-y-pad section-x-pad  before:h-full before:w-full before:bottom-0 before:right-0 before:left-0  before:top-0 before:-skew-y-[5deg] before:bg-right-skew  before:-z-10">
				<div className="container section-x-pad z-10">
					<div className="flex lg:flex-row flex-col lg:gap-20 gap-5">
						<div className="lg:w-[315px] f-col gap-4">
							<div className="f-col gap border border-gray-500 rounded-md  p-5">
								<h4 className="para-head">Chat with us</h4>
								<span className="para">Our friendly team is here to help</span>
								<span className="para">info@recruittoday.co</span>
							</div>
							{/* <div className="f-col gap border border-gray-500 rounded-md  p-5">
								<h4 className="para-head">Chat with us</h4>
								<span className="para">Our friendly team is here to help</span>
								<span className="para">hr@RecruitToday.com</span>
							</div> */}
						</div>
						<div className="w-full">
							<div className="f-col md:gap-2 gap-1 text-black">
								<h3 className="mg:text-[1.8rem] text-[1.5rem] md:font-medium">
									We'd love to help
								</h3>
								<p className="contant-para">
									Reach out and we'll get in touch within 24 hours.
								</p>
								<span className="block border-b border-gray-500"></span>
								<h3 className="mg:text-[1.8rem] text-[1.5rem] md:font-medium">
									Existing Consultants
								</h3>
								<p className="contant-para">
									For any inquiries, kindly reach out to us via email at{" "}
									<span className="hover:underline hover:text-secondary cursor-pointer transition-all">
										info@recruittoday.co
									</span>
								</p>
								<span className="block border-b border-gray-500"></span>
								<div className="f-col md:gap-2 gap-1 text-black">
									<h3 className="mg:text-[1.8rem] text-[1.5rem] md:font-medium text-center mt-5">
										FOR EMPLOYERS
									</h3>
									<p className="contant-para">
										Please fill out the form below and we'll get back within 24
										hours.
									</p>
									<form className="grid md:grid-cols-2 grid-cols-1 gap">
										<div>
											<label for="name" className="block">
												Your name*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Your email
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Company*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Business email*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Company Phone*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Zip Code*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Job Title*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div>
											<label for="name" className="block">
												Specialization needed*
											</label>
											<input
												type="text"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div className="md:col-span-2">
											<label htmlFor="staff" className="block">
												What type of staff do you need?*
											</label>
											<div className="flex items-start justify-start gap">
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="temporaryStaff"
														name="staff"
														className="border-b border-gray-500 bg-transparent p-2"
													/>
													<label htmlFor="temporaryStaff">Temporary</label>
												</div>
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="permanentStaff"
														name="staff"
														className="border-b border-gray-500 bg-transparent p-2"
													/>
													<label htmlFor="permanentStaff">Permanent</label>
												</div>
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="bothStaff"
														name="staff"
														className="border-b border-gray-500 bg-transparent p-2"
													/>
													<label htmlFor="bothStaff">Both</label>
												</div>
											</div>
										</div>

										<div className="md:col-span-2">
											<label for="estimatedStaff" className="block">
												What is the estimated staff that you need?*
											</label>
											<div className="flex items-start justify-start gap">
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="staff"
														name="staff"
														className=" border-b border-gray-500 bg-transparent p-2"
														placeholder="Name"
													/>
													<label for="staff">1-74 employees</label>
												</div>
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="staff"
														name="staff"
														className=" border-b border-gray-500 bg-transparent p-2"
														placeholder="Name"
													/>
													<label for="staff">75+ employees</label>
												</div>
											</div>
										</div>
										<div className="md:col-span-2">
											<label for="locations" className="block">
												Do you have locations that will require onsite presence
												or management?*
											</label>
											<div className="flex items-start justify-start gap">
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="staff"
														name="staff"
														className=" border-b border-gray-500 bg-transparent p-2"
														placeholder="Name"
													/>
													<label for="staff">Yes</label>
												</div>
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="staff"
														name="staff"
														className=" border-b border-gray-500 bg-transparent p-2"
														placeholder="Name"
													/>
													<label for="staff">No</label>
												</div>
												<div className="flex md:gap-0.8 gap-0.6">
													<input
														type="radio"
														id="staff"
														name="staff"
														className=" border-b border-gray-500 bg-transparent p-2"
														placeholder="Name"
													/>
													<label for="staff">I don't Know</label>
												</div>
											</div>
										</div>
										<div className="md:col-span-2">
											<label for="name" className="block">
												Tell us a little about the Project..
											</label>
											<textarea
												type="text"
												rows="5"
												id="name"
												className="w-full border-b border-gray-500 bg-transparent p-2"
												placeholder="Name"
											/>
										</div>
										<div className="md:col-span-2">
											<button className="bg-blue-500  text-white py-2 px-6 rounded-md font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
												submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
