import React, { useEffect } from "react";
import { images } from "../../assets";
import CheckBox from "../../components/checkbox";
import { scrollToTop } from "../../utils/helper";

const AboutUs = () => {
	useEffect(() => {
		scrollToTop();
	}, []);
	return (
		<div>
			<div className="bg-about-banner bg-no-repeat bg-center bg-cover section-y-pad">
				<div className="container relative">
					<div className="max-w-[800px] w-full m-auto section-y-pad">
						<div className="f-col gap text-white lg:gap-[40px] md:gap-[30px] gap-4 ">
							<h1 className="text-center lg:text-7xl md:text-6xl text-5xl leading-tight font-semibold">
								WHO WE ARE?
							</h1>
							<p className="text-center lg:text-2xl md:text-xl text-lg font-normal wordspaing  ">
								Recruit Today stands tall as a global force in talent recruitment. Our vision is nothing short of revolutionary - to become the world's leading company for finding the best talent while prioritizing fairness and equal opportunity for all candidates. This unique approach promises a future where businesses find the most specialized talent seamlessly matched with diverse and highly qualified individuals.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="lg:h-[572px] md:h-[472px] h-[252px] container lg:-mt-[6rem] md:-mt-[5rem] -mt-[4rem] px-6 w-full overflow-hidden">
				<img
					src={images.aboutBanner}
					className=" w-full h-full rounded-xl"
					alt="about-banner"
				/>
			</div>
			<div className="container section-y-pad section-x-pad gap f-col text-center  ">
				<h2 className="lg:text-4xl md:text-3xl text-2xl text-center md:font-semibold font-medium text-black">
					WHY CHOOSE US?
				</h2>

				<p className="para">
					Our professional recruiters have years of experience locating
					excellent jobs across many sectors, and we recognize that each
					candidate is unique. Our customized solutions ensure that you get the
					right job for your talents. Our wide network and industry
					understanding enable us to find the greatest jobs and opportunities.
					Our successful placements and delighted clients attest to our talents.
					We leverage the latest technology to streamline the recruitment
					process.
				</p>
			</div>
		</div>
	);
};

export default AboutUs;
