import React, { useEffect, useState } from "react";
import ServicesCard from "../../components/serviceCard";
import {
	EMPLOYMENT_TYPE,
	FIND_A_JOB,
	JOb_TYPE,
	NUMBER_OF_POSITIONS,
	PRIORITY,
	areaFoucsData,
	servicesData,
} from "../../data";
import { scrollToTop } from "../../utils/helper";
import Modal from "../../components/modals/FindAJobModal";
import FindEmployee from "../../components/modals/findEmployee";
import RecruitServicesComp from "../../components/recruiteServicesComp";
import ServiceModal from "../../components/modals/ServiceSideModal";

const areaFocusData = [
	{
		id: 1,
		text: "Information Technology",
		description: `We concentrate on recruiting elite talent for the rapidly evolving field of information technology. Our expertise covers a wide range of IT specialties, including software engineering, cloud computing, cybersecurity, data analytics, and IT infrastructure. We utilize cutting-edge recruitment technologies and a deep understanding of IT trends to identify and attract top professionals who can drive technological advancements and solve complex challenges. By aligning candidates' technical skills and career aspirations with the specific needs of leading tech companies, we ensure the optimal fit for both our clients and candidates, fostering growth and innovation in the IT sector.`,
	},
	{
		id: 2,
		text: "Healthcare",
		description: `We focus on recruiting top talent for the healthcare sector, addressing the critical need for skilled professionals across various roles. Our expertise encompasses a range of positions, from physicians and nurses to medical administrators and allied health professionals. We leverage our deep understanding of the healthcare industry and its unique demands to connect qualified candidates with opportunities that drive excellence in patient care and operational efficiency. By combining industry knowledge with a strategic recruitment approach, we help healthcare organizations build strong teams that enhance their capacity to deliver high-quality, compassionate care.`,
	},
	{
		id: 3,
		text: "Fintech",
		description: `We specialize in recruiting exceptional talent for the dynamic fintech sector, bridging the gap between finance and technology. Our focus includes sourcing professionals across a range of roles, such as financial analysts, software developers, blockchain experts, and compliance officers. We utilize our in-depth understanding of fintech trends and innovations to match top-tier candidates with leading firms at the forefront of financial technology. By aligning candidates’ technical prowess and financial acumen with the evolving needs of fintech companies, we drive growth and innovation in this rapidly advancing industry.`,
	},
	{
		id: 4,
		text: `Engineering`,
		description: `We are dedicated to recruiting top engineering talent across a wide array of disciplines, including civil, mechanical, electrical, and manufacturing engineering. Our focus is on identifying skilled professionals who excel in both technical expertise and innovative problem-solving. By leveraging industry insights and advanced recruitment strategies, we connect engineers with opportunities that match their skills and career aspirations, helping leading organizations build robust teams capable of driving engineering excellence and technological advancement. Our goal is to support both candidates and companies in achieving success through strategic, tailored recruitment solutions.`,
	},
	{
		id: 5,
		text: `Aerospace`,
		description: `We specialize in recruiting elite talent for the aerospace industry, addressing the sector’s unique needs and challenges. Our focus spans a range of roles, including aerospace engineers, avionics specialists, and project managers, with expertise in both commercial and defence aerospace. We leverage our deep understanding of aerospace technologies and industry standards to match highly skilled professionals with leading organizations working on cutting-edge projects. By aligning candidates’ technical capabilities and career goals with the evolving demands of the aerospace sector, we contribute to advancing innovation and excellence in this critical field.    `,
	},
	{
		id: 6,
		text: "Retail",
		description: `We excel in recruiting top talent for the retail sector, addressing a diverse range of roles from store managers and sales associates to supply chain experts and e-commerce specialists. Our focus is on understanding the unique demands of the retail industry, including customer service excellence, inventory management, and digital transformation. By leveraging our industry insights and advanced recruitment methods, we connect dynamic professionals with leading retail brands, helping them build strong teams that drive sales, enhance customer experiences, and adapt to evolving market trends.`,
	},
];

const Home = () => {
	useEffect(() => {
		scrollToTop();
	}, []);
	const [selectedJog, setSelectedJob] = useState(false);
	const [modal, setModal] = useState(false);
	const [modal2, setModal2] = useState(false);
	const [modal3, setModal3] = useState(false);

	const [modalData, setModalData] = useState(null);

	const handleCardClick = (data) => {
		setModalData(data);
	};

	const changeHandler = (e) => {
		const { value } = e.target;
		setSelectedJob(value);
		if (value === "Resume Building") {
			document.getElementById("resumeBuilding").showModal();
		}
		if (value === "Resume and LinkedIn Optimization") {
			document.getElementById("resume_and_linkedin_optimization").showModal();
		}
		if (value === "Job Hunting and Interview Preparation") {
			document.getElementById("resumeBuilding").showModal();
		}
		if (value === "All Services") {
			document.getElementById("resumeBuilding").showModal();
		}
	};

	return (
		<>
			<section className="bg-white">
				<div className="bg-home-banner bg-no-repeat bg-current bg-cover bg-fixed">
					<div className="container section-y-pad px-6">
						<div className="flex flex-col md:gap-2 gap-1 items-center justify-center">
							<h1 className="stats-box-heading text-white capitalize">
								Staffing & Consulting
							</h1>
							<p className="md:text-base text-sm leading-6 tracking-wide text-white">
								Helping Both The Job Seeker & The Employer Find The Perfect
								Match
							</p>

							<RecruitServicesComp
								modal={modal}
								setModal={setModal}
								modal2={modal2}
								setModal2={setModal2}
							/>
						</div>
					</div>
				</div>

				{/* area of focus */}
				<div className="bg-gradient-to-b from-white to-primary flex items-center justify-center">
					<div className="container px-6 py-12">
						<h2 className="text-center section-heading text-secondary mb-12">
							Areas of Focus
						</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
							{areaFocusData.map((item, index) => (
								<ServicesCard
									key={index}
									text={item.text}
									onClick={() => handleCardClick(item)}
								/>
							))}
						</div>
					</div>
					{modalData && (
						<ServiceModal
							id={modalData.id}
							title={modalData.text}
							setModal3={() => setModalData(null)}
							modal3={!!modalData}
							description={modalData.description}
						/>
					)}
				</div>

				{/* area of focus */}

				{/* area of services */}
				{/* <div className="bg-service-img bg-no-repeat bg-center bg-cover">
          <div className="container section-y-pad px-6 bg-[rgba(0,0,0,0.2)]">
            <div className="f-col gap-4 ">
              <h2 className="text-white text-center section-heading">
                Our Services
              </h2>

              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {servicesData.map((item, index) => (
                  <ServicesCard key={index} text={item.text} />
                ))}
              </div>
            </div>
          </div>
        </div> */}
				{/* area of services */}

				{/* contact us */}

				{/* You can open the modal using document.getElementById('ID').showModal() method */}
			</section>

			{/*  find_job*/}
			{modal && (
				<Modal
					id="find_job"
					title="Find a Job!"
					modal={modal}
					setModal={setModal}
				/>
			)}
			{/*  find_job*/}

			{modal2 && (
				<FindEmployee
					id="find_employee"
					title="Find an Employee!"
					modal2={modal2}
					setModal2={setModal2}
				/>
			)}
		</>
	);
};

export default Home;
