import brandLogo from "./brand-logo.jpg";
import aboutRecruit from "./about-recruit.png";
import favicon from "./favicon.jpg";
import ellipseBlue from "./ecllipse-150x150.png";
import ellipseYellow from "./ellipse-decor-150x141.png";
import recruit from "./recruit.png";
import homeBanner from "./recruite-banner.jpg";
import aboutBanner from "./about-banner-1-1024x423.png";
import leftQuote from "./left-quote.png";
import vendor from "./vendor-768x432.jpg";
import contract from "./cont-recruit-scaled.jpg";
import permanentRecruitment from "./recruit2-scaled.jpg";
import executiveRecruitment from "./exec-recruit-scaled.jpg";
import RPO from "./rpo-scaled.jpg";
import HRServices from "./hr-service-scaled.jpg";
import projectBasedRecruitment from "./recruit-last-scaled.jpg";
import uploadSign from "./upload-sign.svg";
export const images = {
  brandLogo,
  aboutRecruit,
  favicon,
  ellipseBlue,
  ellipseYellow,
  recruit,
  aboutBanner,
  leftQuote,
  vendor,
  contract,
  permanentRecruitment,
  executiveRecruitment,
  RPO,
  HRServices,
  projectBasedRecruitment,
  uploadSign,
  homeBanner,
};
