import React from "react";

const ServicesCard = ({ text, onClick, id }) => {
	return (
		onClick ? (
			<div
				onClick={onClick}
				className="bg-secondary rounded-md hover:bg-primary h-[240px] md:cursor-pointer cursor-auto transition-all shadow-sm shadow-secondary p-5 pt-[1.5rem] flex flex-col items-center justify-center lg:gap-2 md:gap-1 gap-0.8"
			>
				<h3 className="text-white text-2xl px-[40px] md:font-bold font-semibold text-center leading-none">
					{text}
				</h3>
			</div>
		) : (
			<a
				href={id}
				className="bg-secondary rounded-md hover:bg-primary h-[240px] md:cursor-pointer cursor-auto transition-all shadow-sm shadow-secondary p-5 pt-[1.5rem] flex flex-col items-center justify-center lg:gap-2 md:gap-1 gap-0.8"
			>
				<h3 className="text-white text-2xl px-[40px] md:font-bold font-semibold text-center leading-none">
					{text}
				</h3>
			</a>
		)
	);
};

export default ServicesCard;
