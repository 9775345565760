import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./layout";
import Home from "./pages/home";
import AboutUs from "./pages/about";
import Services from "./pages/services";
import ContactUs from "./pages/contact-us";
import TermsAndCondition from "./pages/terms&Condition";
import Applying from "./pages/applying";
//
function App() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Layout />,
			children: [
				{
					path: "/",
					element: <Home />,
				},
				{
					path: "/about",
					element: <AboutUs />,
				},
				{
					path: "/services",
					element: <Services />,
				},
				{
					path: "/contact-us",
					element: <ContactUs />,
				},
				{
					path: "/terms-and-conditions",
					element: <TermsAndCondition />,
				},
				{
					path: "/applying",
					element: <Applying />,
				},
			],
		},
	]);
	return (
		<>
			<RouterProvider router={router}></RouterProvider>
		</>
	);
}

export default App;
