import { is, curryN, gte } from "ramda";

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  );
});
export const in200s = isWithin(200, 299);
export const in400s = isWithin(400, 499);
