import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../utils/helper";
import { images } from "../../assets";
import FileViewer from "react-file-viewer";
import { useAppContext } from "../../context/appContext";
import {
  CAR_AND_LISCENSE_CHOICES,
  EDUCATION_CHOICES,
  EMPLOYMENT_TYPE,
  EXPECTED_SALARY_CHOICES,
  GENDER_CHOICES,
  JOb_TYPE,
  MARTIAL_STATUS_CHOICES,
  NOTICE_PERIOD_CHOICES,
  PREFERRED_LOCATION_CHOICES,
  SKILLS_CHOICES,
  VISA_STATUS_CHOICES,
} from "../../data";
import toast from "react-hot-toast";
import { in200s, in400s } from "../../helper/index";

const Applying = () => {
  const [inputValue, setInputValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: 0,
    date_of_birth: "",
    current_location: "",
    expected_salary: "",
    years_of_experience: "",
    education: "",
    notice_period: 0,
    notice_preference: "",

    preferred_location: "",
    visa_status: "",
    car_and_liscense: "",
    martial_status: "",
    address: "",
    country: "",
    gender: "",
    skills: "",
    linkedin_username: "",
    linkedin_password: "",
    resume: "",
    cover_letter: "",
  });
  const [otherPreferredLocation, setOtherPreferredLocation] = useState(false);
  const [resumePreview, setResumePreview] = useState(null); // State for resume preview
  const [coverLetterPreview, setCoverLetterPreview] = useState(null);
  const [laoding, setLoading] = useState(false);
  const { apply } = useAppContext();
  useEffect(() => {
    scrollToTop();
  }, []);
  // const handleChange = (e) => {
  //   setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  // };

  // const handleChange = (e) => {
  //   if (e.target.name === "resume" || e.target.name === "cover_letter") {
  //     // If it's a file input, set the file to state and preview it
  //     const file = e.target.files[0];
  //     setInputValue({ ...inputValue, [e.target.name]: file });
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         if (e.target.name === "resume") {
  //           setResumePreview(reader.result);
  //         } else if (e.target.name === "cover_letter") {
  //           setCoverLetterPreview(reader.result);
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   } else {
  //     setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  //   }
  // };

  const handleChange = (e) => {
    if (e.target.name === "preferred_location" && e.target.value === "Other") {
      setOtherPreferredLocation(true);
    }
    if (
      e.target.name === "preferred_location" &&
      e.target.value === "Current Area only"
    ) {
      setOtherPreferredLocation(false);
    }
    if (
      e.target.name === "preferred_location" &&
      e.target.value === "All over the UK"
    ) {
      setOtherPreferredLocation(false);
    }
    if (e.target.name === "preferred_location" && e.target.value === "") {
      setOtherPreferredLocation(false);
    }
    if (e.target.name === "resume" || e.target.name === "cover_letter") {
      // If it's a file input, set the file to state
      setInputValue({ ...inputValue, [e.target.name]: e.target.files[0] });
      if (e.target.name === "resume") {
        // If it's the resume input, clear the previous preview and set the new one
        setResumePreview(null); // Clear previous preview
        setResumePreview(URL.createObjectURL(e.target.files[0])); // Set new preview
      } else if (e.target.name === "cover_letter") {
        // If it's the cover letter input, clear the previous preview and set the new one
        setCoverLetterPreview(null); // Clear previous preview
        setCoverLetterPreview(URL.createObjectURL(e.target.files[0])); // Set new preview
      }
    } else {
      setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !inputValue.first_name ||
      !inputValue.last_name ||
      !inputValue.email ||
      !inputValue.phone_no ||
      !inputValue.date_of_birth ||
      !inputValue.current_location ||
      !inputValue.expected_salary ||
      !inputValue.years_of_experience ||
      !inputValue.education ||
      !inputValue.notice_period ||
      !inputValue.notice_preference ||
      !inputValue.preferred_location ||
      !inputValue.visa_status ||
      !inputValue.car_and_liscense ||
      !inputValue.martial_status ||
      !inputValue.address ||
      !inputValue.country ||
      !inputValue.gender ||
      !inputValue.skills ||
      !inputValue.linkedin_username ||
      !inputValue.linkedin_password ||
      !inputValue.resume ||
      !inputValue.cover_letter
    ) {
      toast.error("Please fill all the required fields");
      setLoading(false);
      return;
    }

    setLoading(true);
    // const formData = new FormData();
    // for (const key in inputValue) {
    //   formData.append(key, inputValue[key]);
    // }
    const formData = {
      first_name: inputValue.first_name,
      last_name: inputValue.last_name,
      email: inputValue.email,
      phone_no: inputValue.phone_no,
      date_of_birth: inputValue.date_of_birth,
      current_location: inputValue.current_location,
      expected_salary: inputValue.expected_salary,
      years_of_experience: inputValue.years_of_experience,
      education: inputValue.education,
      notice_period: `${inputValue.notice_period} ${inputValue.notice_preference}`,
      preferred_location: inputValue.preferred_location,
      visa_status: inputValue.visa_status,
      car_and_liscense: inputValue.car_and_liscense,
      martial_status: inputValue.martial_status,

      address: inputValue.address,
      country: inputValue.country,
      gender: inputValue.gender,
      skills: inputValue.skills,
      linkedin_username: inputValue.linkedin_username,
      linkedin_password: inputValue.linkedin_password,
      resume: inputValue.resume,
      cover_letter: inputValue.cover_letter,
    };

    const response = await apply(formData);
    console.log("response", response);
    if (in200s(response?.status)) {
      toast.success("your data submit successfully");
      setInputValue({
        first_name: "",
        last_name: "",
        email: "",
        phone_no: 0,
        date_of_birth: "",
        current_location: "",
        expected_salary: "",
        years_of_experience: "",
        education: "",
        notice_period: 0,
        notice_preference: "",
        preferred_location: "",
        visa_status: "",
        car_and_liscense: "",
        martial_status: "",
        address: "",
        country: "",
        gender: "",
        skills: "",
        linkedin_username: "",
        linkedin_password: "",
        resume: "",
        cover_letter: "",
      });
    }
    if (in400s(response?.status)) {
      const keys = Object.keys(response.data);
      console.log(keys);
      keys.map((key) => {
        response.data[key].map((value) => {
          toast.error(`${key}: ${value}`);
        });
      });
    }
    setLoading(false);
  };
  return (
    <div className="section-y-pad">
      <div className="container section-x-pad">
        <div className="bg-white shadow-lg section-y-pad">
          <h3 className='relative stats-box-heading md:pb-8 pb-4 text-center before:absolute before:content-[""] before:bottom-0 before:border-b-4 before:border-secondary md:before:w-1/6 before:w-1/2 before:left-2/4 before:-translate-x-2/4  before:h-3 capitalize'>
            job applications
          </h3>
          <form
            className="grid md:grid-cols-2 section-y-pad grid-cols-1 md:gap-2 gap-1 md:px-4 px-2"
            onSubmit={handlesubmit}
            encType="multipart/form-data"
          >
            {/* first name */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="first_name" className="label-text">
                first name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className="input-field"
                value={inputValue.first_name}
                onChange={handleChange}
              />
            </div>
            {/* first name */}

            {/* last name */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="last_name" className="label-text">
                last name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="last_name"
                value={inputValue.last_name}
                onChange={handleChange}
                name="last_name"
                className="input-field"
              />
            </div>
            {/* last name */}

            {/* email */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="email" className="label-text">
                email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={inputValue.email}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            {/* email */}

            {/* phone_no */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="phone_no" className="label-text">
                phone/mobile<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                id="phone_no"
                value={inputValue.phone_no}
                onChange={handleChange}
                name="phone_no"
                className="input-field"
              />
            </div>
            {/* phone_no */}

            {/* date_of_birth */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="date_of_birth" className="label-text">
                Date of birth<span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                value={inputValue.date_of_birth}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            {/* date_of_birth */}

            {/* current_location */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="current_location" className="label-text">
                current location
              </label>
              <input
                type="text"
                id="current_location"
                name="current_location"
                className="input-field"
                value={inputValue.current_location}
                onChange={handleChange}
              />
            </div>
            {/* current_location */}

            {/* expected_salary */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="expected_salary" className="label-text">
                expected salary range<span className="text-red-500">*</span>
              </label>

              <select
                name="expected_salary"
                id="expected_salary"
                value={inputValue.expected_salary}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {EXPECTED_SALARY_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
                {/* <option value="40,000 - 50,000">40,000 - 50,000</option>
                <option value="100,000 - 150,000">100,000 - 150,000</option>
                <option value="150,000 - 200,000">150,000 - 200,000</option>
                <option value="200,000 - 250,000">200,000 - 250,000</option>
                <option value="250,000 - 300,000">250,000 - 300,000</option>
                <option value="300,000 - 350,000">300,000 - 350,000</option>
                <option value="350,000 - 400,000">350,000 - 400,000</option>
                <option value="400,000 - 450,000">400,000 - 450,000</option>
                <option value="450,000 - 500,000">450,000 - 500,000</option> */}
              </select>
            </div>
            {/* expected_salary */}

            {/* years_of_experience */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="years_of_experience" className="label-text">
                year of experience
              </label>
              <input
                type="number"
                id="years_of_experience"
                name="years_of_experience"
                value={inputValue.years_of_experience}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            {/* years_of_experience */}

            {/* education */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="education" className="label-text">
                education<span className="text-red-500">*</span>
              </label>
              <select
                name="education"
                id="education"
                value={inputValue.education}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {EDUCATION_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
                {/* <option value="intermidiate">Intermidiate</option>
                <option value="Bachelor Degree">Bachelor Degree</option>
                <option value="Master">Master</option>
                <option value="PHD">PHD</option> */}
              </select>
            </div>
            {/* education */}

            {/* notice_period */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="notice_period" className="label-text">
                notice period<span className="text-red-500">*</span>
              </label>
              <div className="flex md:gap-0.8 gap-0.5">
                <input
                  type="number"
                  value={inputValue.notice_period}
                  onChange={handleChange}
                  id="notice_period"
                  name="notice_period"
                  className="input-field w-[80%]"
                />
                <select
                  name="notice_preference"
                  id="notice_preference"
                  value={inputValue.notice_preference}
                  onChange={handleChange}
                  className="input-field w-[20%]"
                >
                  <option value={""}>-select-</option>
                  {NOTICE_PERIOD_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* notice_period */}

            {/* preferred_location */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="preferred_location" className="label-text">
                preferred locations for the job
              </label>
              <div className="flex md:gap-0.8 gap-0.5">
                <select
                  name="preferred_location"
                  id="preferred_location"
                  value={inputValue.preferred_location}
                  onChange={handleChange}
                  className={`input-field ${
                    otherPreferredLocation ? `w-1/2` : `w-full`
                  }`}
                >
                  <option value={""}>-select-</option>
                  {PREFERRED_LOCATION_CHOICES.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                  {/* <option value="remote">Remote</option>
                <option value="Onsite">Onsite</option> */}
                </select>
                <div className={`${otherPreferredLocation ? `w-1/2` : ``}`}>
                  {inputValue.preferred_location === "Other" && (
                    <input
                      type="text"
                      id="other_preferred_location"
                      name="other_preferred_location"
                      value={inputValue.other_preferred_location}
                      onChange={handleChange}
                      className="input-field w-full"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* preferred_location */}

            {/* visa_status */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="visa_status" className="label-text">
                visa status
              </label>
              <select
                name="visa_status"
                id="visa_status"
                value={inputValue.visa_status}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {VISA_STATUS_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
                {/* <option value="remote">option 1</option>
                <option value="Onsite">option 2</option> */}
              </select>
            </div>
            {/* visa_status */}

            {/* car_and_liscense */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="car_and_liscense" className="label-text">
                Car and License
              </label>
              <select
                name="car_and_liscense"
                id="car_and_liscense"
                className="input-field"
                value={inputValue.car_and_liscense}
                onChange={handleChange}
              >
                <option value={""}>-select-</option>
                {CAR_AND_LISCENSE_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
                {/* <option value="remote">Yes</option>
                <option value="Onsite">No</option> */}
              </select>
            </div>
            {/* car_and_liscense */}

            {/* martial_status */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="martial_status" className="label-text">
                marital status
              </label>
              <select
                name="martial_status"
                id="martial_status"
                value={inputValue.martial_status}
                className="input-field"
                onChange={handleChange}
              >
                <option value={""}>-select-</option>
                {MARTIAL_STATUS_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
            {/* martial_status */}
            {/* gender  */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="gender" className="label-text">
                gender<span className="text-red-500">*</span>
              </label>
              <select
                name="gender"
                id="gender"
                value={inputValue.gender}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {GENDER_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
                {/* <option value="male">Male</option>
                <option value="female">female</option> */}
              </select>
            </div>
            {/* gender  */}

            {/* address */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="address" className="label-text">
                address<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="address"
                name="address"
                className="input-field"
                value={inputValue.address}
                onChange={handleChange}
              />
            </div>
            {/* address */}

            {/* country */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="country" className="label-text">
                country<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="country"
                name="country"
                className="input-field"
                value={inputValue.country}
                onChange={handleChange}
              />
            </div>
            {/* country */}

            {/* Postal Code */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="postal_code" className="label-text">
                Postal Code<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="postal_code"
                name="postal_code"
                className="input-field"
                value={inputValue.country}
                onChange={handleChange}
              />
            </div>
            {/* Postal Code */}

            {/* skills */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="skills" className="label-text">
                Current Title<span className="text-red-500">*</span>
              </label>
              <select
                name="skills"
                id="skills"
                value={inputValue.skills}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {SKILLS_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
            {/* skills */}

            {/* Preferred Title */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="-preferred_title" className="label-text">
                Preferred Title<span className="text-red-500">*</span>
              </label>
              <select
                name="preferred_title"
                id="preferred_title"
                value={inputValue.skills}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {SKILLS_CHOICES.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
            {/* Preferred Title */}

            {/* job_type */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="job_type" className="label-text">
                Job Type<span className="text-red-500">*</span>
              </label>
              <select
                name="job_type"
                id="job_type"
                value={inputValue.skills}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {JOb_TYPE.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
            {/* job_type */}

            {/* Employment Type */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="employment_type" className="label-text">
                Employment Type<span className="text-red-500">*</span>
              </label>
              <select
                name="employment_type"
                id="employment_type"
                value={inputValue.skills}
                onChange={handleChange}
                className="input-field"
              >
                <option value={""}>-select-</option>
                {EMPLOYMENT_TYPE.map((choice) => (
                  <option key={choice} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
            {/* Employment Type */}

            {/* linkedin_username */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="linkedin_username" className="label-text">
                linkedin credentials email or username{" "}
                {/* <span className="text-red-500">*</span> */}
              </label>
              <input
                type="text"
                id="linkedin_username"
                name="linkedin_username"
                value={inputValue.linkedin_username}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            {/* linkedin_username */}

            {/* linkedin_password */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="linkedin_password" className="label-text">
                linkedin credentials password{" "}
                {/* <span className="text-red-500">*</span> */}
              </label>
              <input
                type="text"
                id="linkedin_password"
                name="linkedin_password"
                className="input-field"
                value={inputValue.linkedin_password}
                onChange={handleChange}
              />
            </div>
            {/* linkedin_password */}

            {/* resume */}
            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="resume" className="label-text">
                upload CV/Resume
                <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                className="input-field"
                // value={inputValue.resume}
                onChange={handleChange}
              />
            </div>
            {/* resume */}

            {/* <div className="f-col gap-0.8 md:gap-0.1">
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="Ffile" className="label-text">
                  upload CV/Resume
                </label>
                <label htmlFor="resume" className="upload_image">
                  <div className="upload_sign">
                    <p style={{ color: "#ffffff" }}>upload CV/Resume </p>
                    <div className="image_container">
                      <img src={images.uploadSign} alt="uploadSign" />
                    </div>
                  </div>
                  <input
                    type="file"
                    id="resume"
                    style={{ display: "none" }}
                    alt="uploadSign"
                    value={inputValue.resume}
                    onChange={handleChange}
                    name="resume"
                    accept="image/*"
                    onChange={uploadImage}
                  />
                </label>
              </div>
              {resumePreview && (
                <div className="file-preview">
                  <img src={resumePreview} alt="Resume Preview" />
                </div>
              )}
            </div> */}
            {/* <div className="f-col gap-0.8 md:gap-0.1">
              <div className="f-col gap-0.8 md:gap-0.1">
                <label htmlFor="Ffile" className="label-text">
                  upload cover letter
                </label>
                <label htmlFor="cover_letter" className="upload_image">
                  <div className="upload_sign">
                    <p style={{ color: "#ffffff" }}>upload Cover letter</p>
                    <div className="image_container">
                      <img src={images.uploadSign} alt="uploadSign" />
                    </div>
                  </div>
                  <input
                    id="cover_letter"
                    style={{ display: "none" }}
                    alt="uploadSign"
                    name="cover_letter"
                    value={inputValue.cover_letter}
                    onChange={handleChange}
                    type="file"
                    accept="image/*"
                  />
                </label>
              </div>
              {/* {coverLetterPreview && (
                <FileViewer
                  fileType="pdf"
                  filePath={coverLetterPreview}
                 
                />
                
              )} 
            </div> */}

            {/* cover_letter */}

            <div className="f-col gap-0.8 md:gap-0.1">
              <label htmlFor="cover_letter" className="label-text">
                upload cover letter<span className="text-red-500">*</span>
              </label>
              <input
                // value={inputValue.cover_letter}
                onChange={handleChange}
                type="file"
                id="cover_letter"
                name="cover_letter"
                className="input-field"
              />
            </div>
            {/* cover_letter */}

            <div className=" md:col-span-2   flex item-center">
              <button
                className="lg:inline-flex items-center border bg-primary lg:hover:bg-transparent lg:hover:text-black transition-all text-white border-primary  md:px-[2rem] px-[1.5rem] md:py-[1rem] py-[0.8rem] text-xl capitalize text-wrap rounded-lg  text-center "
                type="submit"
              >
                {laoding ? "submiting..." : "submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Applying;
