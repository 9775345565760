import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { images } from "../assets";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { LuInstagram } from "react-icons/lu";

import RegisterModal from "../pages/RegisterModal";
import {
	SiGmail,
	SiLetterboxd,
	SiMailgun,
	SiMinutemailer,
} from "react-icons/si";
import { areaFoucsData } from "../data";

const Layout = () => {
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [registerPopUp, setRegisterPopUp] = useState(false);
	return (
		<>
			<div className="drop-shadow-xl bg-white z-[999] sticky top-0">
				<nav className="container ">
					<div className="lg:flex lg:justify-between lg:items-center z-50 lg:p-4 md:p-3 p-2  ">
						<div className="flex justify-between items-center  px-4 lg:px-0 ">
							<NavLink to={"/"} className="brand-logo-size">
								<img
									src={images.brandLogo}
									alt="brand-logo"
									className="h-full w-full"
								/>
							</NavLink>

							<button
								className="text-gray-500 focus:outline-none lg:hidden"
								onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M4 6h16M4 12h16m-7 6h7"
									></path>
								</svg>
							</button>
						</div>

						<div
							//   className={`${
							//     isMobileMenuOpen ? "flex" : "hidden"
							//   } lg:flex flex-col lg:flex-row space-x-0 lg:space-x-8 px-4 py-2 lg:px-0 lg:py-0`}
							className={`${
								isMobileMenuOpen ? "flex" : "hidden"
							} lg:flex flex-col lg:flex-row  flex-wrap  px-4 py-2 lg:px-0 lg:py-0 lg:pt-0 pt-4 lg:shadow-none shadow-lg lg:relative absolute lg:top-0 lg:w-auto w-full  lg:bg-transparent bg-white left-0 transition-all`}
							id="mobile-menu"
						>
							<NavLink to="/" className="nav-link">
								<svg
									className="nav-svg-link"
									fill="none"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<title>home icon</title>{" "}
									<path
										d="M22 22L2 22"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
									></path>{" "}
									<path
										d="M2 11L6.06296 7.74968M22 11L13.8741 4.49931C12.7784 3.62279 11.2216 3.62279 10.1259 4.49931L9.34398 5.12486"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
									></path>{" "}
									<path
										d="M15.5 5.5V3.5C15.5 3.22386 15.7239 3 16 3H18.5C18.7761 3 19 3.22386 19 3.5V8.5"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
									></path>{" "}
									<path
										d="M4 22V9.5"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
									></path>{" "}
									<path
										d="M20 9.5V13.5M20 22V17.5"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
									></path>{" "}
									<path
										d="M15 22V17C15 15.5858 15 14.8787 14.5607 14.4393C14.1213 14 13.4142 14 12 14C10.5858 14 9.87868 14 9.43934 14.4393M9 22V17"
										// stroke="#1C274C"
										// stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>{" "}
									<path
										d="M14 9.5C14 10.6046 13.1046 11.5 12 11.5C10.8954 11.5 10 10.6046 10 9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5Z"
										// stroke="#1C274C"
										// stroke-width="1.5"
									></path>{" "}
								</svg>
							</NavLink>
							<NavLink to="/about" className="nav-link">
								About Us
							</NavLink>
							<NavLink to="/services" className="nav-link">
								Services
							</NavLink>
							<NavLink to="/contact-us" className="nav-link">
								Contact Us
							</NavLink>
							<NavLink to="/terms-and-conditions" className="nav-link">
								Terms & Conditions
							</NavLink>

							{/* <button
                className="btn"
                onClick={() => setRegisterPopUp(!registerPopUp)}
              >
                Register Now
              </button> */}

							{/* <div className="relative lg:inline-block">
                <button className="text-gray-700 lg:text-blue-600 hover:text-blue-800">
                  English
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
              </div> */}
						</div>
					</div>
				</nav>
			</div>
			{isMobileMenuOpen && (
				<div
					onClick={() => setMobileMenuOpen((val) => !val)}
					className=" z-[998] min-h-screen fixed top-0 left-0 w-full bg-white bg-opacity-5"
				></div>
			)}
			<div>
				<Outlet />
			</div>

			{/* contact us */}

			{/* social media contact */}
			{/* <div className="bg-[#F7F7F7]">
        <div className="container px-6 lg:py-[4rem] md:py-[3rem] py-[2.5rem] ">
          <div className="flex lg:flex-row  md:justify-between items-center flex-col gap ">
            <div className="brand-logo-size">
              <img src={images.recruit} alt="recruit" />
            </div>
            <div className="item-center gap">
              <Link
                className="social-icon-size bg-[#41C051]"
                to="https://wa.link/44bm39"
                target="_blank"
              >
                <FaWhatsapp />
              </Link>
              <Link
                className="social-icon-size bg-[#0077B5]"
                to={"https://instagram.com/recruit_today"}
                target="_blank"
              >
                <IoLogoLinkedin />
              </Link>
              <Link
                className="social-icon-size bg-[#CF2872]"
                to={"https://www.linkedin.com/company/recruit-today/"}
                target="_blank"
              >
                <LuInstagram />
              </Link>
              <Link
                className="social-icon-size bg-[#B5001C]"
                to={"recruittoday.com@gmail.com"}
                target="_blank"
              >
                <SiGmail />
              </Link>
            </div>
          </div>
        </div>
      </div> */}
			{/* social media contact */}
			<div className="bg-white">
				<div className="container px-6 lg:py-[3rem] md:py-[2rem] py-[1.5rem]">
					<div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 lg:gap-8 md:gap-6 gap-5">
						<div className="f-col gap-4 md:col-span-2 col-span-1 ">
							<h4 className="footer-header">Why Us</h4>
							<p className="footer-item">
								We specialize in connecting top talent with leading companies
								through our personalized, expert recruitment solutions. With
								years of industry experience, an extensive network, and a
								commitment to quality, we provide thorough candidate screenings
								and innovative hiring strategies. Our tailored approach ensures
								that both employers and job seekers achieve their goals, backed
								by continuous support and guidance. Choose us as a recruitment
								partner dedicated to excellence and success.
							</p>
						</div>
						<div className="f-col gap-4  ">
							<h4 className="footer-header">Areas of Focus</h4>
							<ul className="f-col gap">
								{areaFoucsData.map((item, index) => (
									<li key={index} className="footer-item">
										{item.text}
									</li>
								))}
							</ul>
						</div>
						<div className="f-col gap-4 ">
							<h4 className="footer-header">Services</h4>
							<ul className="f-col gap">
								<li className="footer-item">Contingent Recruitment Services</li>
								<li className="footer-item">Permanent Recruitment Services</li>
								<li className="footer-item">Recruitment Process Outsourcing</li>
								<li className="footer-item">Contract-based Recruitment</li>
							</ul>
						</div>

						<div className="f-col gap-4 ">
							<h4 className="footer-header">Contact</h4>
							<ul className="flex gap flex-wrap">
								<Link
									className="social-icon-size bg-[#41C051]"
									to="https://wa.link/eir54l"
									target="_blank"
								>
									<FaWhatsapp />
								</Link>
								<Link
									className="social-icon-size bg-[#0077B5]"
									to={"https://www.linkedin.com/company/recruit-today/"}
									target="_blank"
								>
									<IoLogoLinkedin />
								</Link>
								<Link
									className="social-icon-size bg-[#CF2872]"
									to={"https://instagram.com/recruit_today"}
									target="_blank"
								>
									<LuInstagram />
								</Link>
								<Link
									className="social-icon-size bg-[#B5001C]"
									to={"info@recruittoday.co"}
									target="_blank"
								>
									<SiGmail />
								</Link>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-primary py-6 text-center">
				<span className="text-white capitalize md:text-base text-sm md:font-bold font-semibold">
					&copy; recruit taday 2006-2023
				</span>
			</div>
			{registerPopUp && (
				<RegisterModal
					title="register"
					registerModal={RegisterModal}
					setRegisterModal={setRegisterPopUp}
				/>
			)}
		</>
	);
};

export default Layout;
