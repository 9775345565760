import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import TermAndConditions from "../termAndConditions";
import toast from "react-hot-toast";
import { useAppContext } from "../../../context/appContext";
import { in200s } from "../../../helper";
import { CLIENT_TYPE_CHOICES } from "../../../data";
import { sendLoginEmail } from "../../../utilses/email";
const ResumeBuilding = ({
	id,
	title,
	resumeBuildingModal,
	setResumeBuildingModal,
}) => {
	const [resumeBuildingCondition, setResumeBuildingCondition] = useState(false);
	const [conditionPopup, setConditionPopup] = useState(false);
	const { resumeLinkndinOptimization } = useAppContext();
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState({
		first_name: "",
		last_name: "",
		contact_number: "",
		email_address: "",
		current_full_address: "",
		resume: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "resume") {
			setInputValue({ ...inputValue, [e.target.name]: e.target.files[0] });
		} else {
			setInputValue({
				...inputValue,
				[name]: value,
			});
		}
	};
	const submitHandler = async (e) => {
		e.preventDefault();

		// Perform login logic (authentication, etc.)

		// If login is successful
		await sendLoginEmail(inputValue)
			.then((response) => console.log("Email sent successfully:", response))
			.catch((error) => console.error("Error sending email:", error));

		if (!resumeBuildingCondition) {
			toast.error("Please agree to the terms and conditions", {
				// duration: 80000,
				// zIndex: 99999999999,
			});

			return;
		}
		if (
			!inputValue.resume ||
			!inputValue.first_name ||
			!inputValue.last_name ||
			!inputValue.contact_number ||
			!inputValue.email_address ||
			!inputValue.current_full_address
		) {
			toast.error("Please fill all the fields", {
				// duration: 80000,
				// zIndex: 99999999999,
			});
			return;
		}

		const data = {
			first_name: inputValue.first_name,
			last_name: inputValue.last_name,
			contact_number: inputValue.contact_number,
			email_address: inputValue.email_address,
			current_full_address: inputValue.current_full_address,
			resume: inputValue.resume,
			client_type: CLIENT_TYPE_CHOICES[0],
		};
		console.log(data);
		setLoading(true);
		const response = await resumeLinkndinOptimization(data);
		console.log(response);

		if (in200s(response?.status)) {
			toast.success("Your request has been submitted successfully", {
				// duration: 80000,
				// zIndex: 99999999999,
			});

			setInputValue({
				first_name: "",
				last_name: "",
				contact_number: "",
				email_address: "",
				current_full_address: "",
				resume: "",
			});

			setResumeBuildingCondition(false);
			setResumeBuildingModal(!resumeBuildingModal);
		} else {
			toast.error("Something went wrong", {
				// duration: 80000,
				// zIndex: 99999999999,
			});
		}
		setLoading(false);
	};
	return (
		<>
			<div>
				{/*resumeBuilding  */}
				<div  onClick={()=>setResumeBuildingModal(val=>!val)} id={id}  className="modal-container">
					<div  onClick={(e) => e.stopPropagation()}className="modal-box-md !pt-0 ">
						<div className="modal-close">
							{/* if there is a button, it will close the modal */}
							<button
								className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-gray-200 hover:bg-gray-300 text-white"
								onClick={() => {
									setResumeBuildingCondition(false);
									setResumeBuildingModal(!resumeBuildingModal);
								}}
							>
								<IoClose />
							</button>
						</div>
						<div className="flex flex-col md:gap-2 gap-1">
							<h3 className="pop-up-heading">{title}</h3>
							<form
								className="flex md:gap-1 gap-1 flex-col"
								onSubmit={submitHandler}
							>
								<div className="grid md:grid-cols-2 grid-cols-1 md:gap-1 gap-0.1">
									{/* first name */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label htmlFor="first_name" className="label-text">
											First Name <span className="text-red-500">*</span>
										</label>
										<input
											type="text"
											id="first_name"
											value={inputValue.first_name}
											onChange={handleChange}
											name="first_name"
											className="input-field"
										/>
									</div>
									{/* first name */}

									{/* last name */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label htmlFor="last_name" className="label-text">
											last Name <span className="text-red-500">*</span>
										</label>
										<input
											type="text"
											id="last_name"
											value={inputValue.last_name}
											onChange={handleChange}
											name="last_name"
											className="input-field"
										/>
									</div>
									{/* last name */}

									{/* Contact Number */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label htmlFor="contact_number" className="label-text">
											Contact Number <span className="text-red-500">*</span>
										</label>
										<input
											type="text"
											id="contact_number"
											value={inputValue.contact_number}
											onChange={handleChange}
											name="contact_number"
											className="input-field"
										/>
									</div>
									{/* Contact Number*/}

									{/* Email Address  */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label htmlFor="email_address" className="label-text">
											Email Address <span className="text-red-500">*</span>
										</label>
										<input
											type="text"
											id="email_address"
											value={inputValue.email_address}
											onChange={handleChange}
											name="email_address"
											className="input-field"
										/>
									</div>
									{/* Email Address */}

									{/* Current Full Address  */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label
											htmlFor="current_full_address"
											className="label-text"
										>
											Current Full Address{" "}
											<span className="text-red-500">*</span>
										</label>
										<input
											type="text"
											id="current_full_address"
											value={inputValue.current_full_address}
											onChange={handleChange}
											name="current_full_address"
											className="input-field"
										/>
									</div>
									{/* Current Full Address  */}

									{/* resume */}
									<div className="f-col gap-0.8 md:gap-0.1">
										<label htmlFor="resume" className="label-text">
											Resume Upload
											<span className="text-red-500">*</span>
										</label>
										<input
											type="file"
											id="resume"
											name="resume"
											className="input-field"
											// value={inputValue.resume_upload}
											onChange={handleChange}
										/>
									</div>
									{/* resume */}
								</div>

								<div className="flex items-center md:gap-0.8 gap-0.5">
									<input
										type="checkbox"
										className="checkbox border-[1px] focus:ring-1 focsu:ring-primary checkbox-xs rounded-sm border-primary [--chkbg:theme('colors.primary')]"
										name="termAndConditions"
										id="termAndConditions"
										checked={resumeBuildingCondition}
										onChange={(e) => {
											if (e.target.checked) {
												setResumeBuildingCondition(!resumeBuildingCondition);
												setConditionPopup(!conditionPopup);
											}
										}}
									/>
									<label
										htmlFor="termAndConditions"
										className="text-sm text-gray-500"
									>
										I agree to the{" "}
										<span
											className="text-primary cursor-pointer"
											onClick={() => {
												setResumeBuildingCondition(true);
												setConditionPopup(!conditionPopup);
											}}
										>
											terms and conditions
										</span>
									</label>
								</div>
								<div className="flex items-center justify-center">
									<button type="submit" className="btn">
										{loading ? "Submiting..." : "Submit"}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				{/*  resumeBuilding*/}
				{conditionPopup && (
					<TermAndConditions
						id="term_and_conditions"
						conditionPopup={conditionPopup}
						setConditionPopup={setConditionPopup}
					/>
				)}
			</div>
		</>
	);
};

export default ResumeBuilding;
