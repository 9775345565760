import React, { useEffect } from "react";
import { scrollToTop } from "../../utils/helper";
import ServicesCard from "../../components/serviceCard";
import { servicesData } from "../../data";

const Services = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className=" bg-service-img bg-center bg-no-repeat bg-cover bg-fixed">
        <div className="bg-secondary-transparent h-full lg:p-[94px] md:p-[84px] p-[74px]  ">
          <div className="container flex flex-col lg:gap-4 md:gap-3 gap-2 ">


          <div className="text-center">
            <h2 className="stats-box-heading text-white">
              We offer a versatile spectrum of staffing solutions to empower
              your organization's growth
            </h2>
          </div>
          <div className="f-col gap-4 ">
            <h2 className="text-white text-center stats-box-heading">
              Our Services
            </h2>

            <div  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
              {servicesData.map((item, index) => (
                <ServicesCard key={index} text={item.text} id={item.id} />
              ))}
            </div>
          </div>
          </div>
        </div>
      </div>
      {/* Contingent Recruitment */}
      <div id="Contingent-recruitment" className="section-y-pad">
        <div className="flex lg:flex-row flex-col lg:gap-6 gap-5">
          <div className="lg:w-2/4 bg-contract bg-cover bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-auto  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
          <div className="lg:w-2/4 w-full flex items-center justify-start section-y-pad lg:px-0 section-x-pad">
            <div  className=" f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">Contingent Recruitment</h2>
              <p className="para">
                In an ever-evolving business landscape, adaptability is
                paramount. Many of our clients rely on contingent workforce
                solutions to address project-specific needs, manage seasonal
                variations, or fill unexpected talent gaps. Contingent staffing
                enables businesses to adjust their workforce according to
                real-time demands, achieving a balance between agility and cost
                efficiency. By choosing Recruit Today as your contingent
                staffing partner, you gain access to a pool of skilled
                professionals ready to seamlessly integrate into your
                initiatives, ensuring both continuity and effectiveness.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Contingent Recruitment */}

      {/* Permanent Recruitment */}
      <div id="Permanent-recruitment" className="section-y-pad">
        <div className="flex lg:flex-row flex-col-reverse lg:gap-6 gap-5">
          <div className="lg:w-2/4 w-full flex items-center justify-end section-y-pad lg:px-0 section-x-pad">
            <div  className="f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">Permanent Recruitment</h2>
              <p className="para">
                While contingent teams provide flexibility, it is the permanent
                workforce that solidifies the foundation of any organization.
                Permanent hires bring stability, long-term commitment, and an
                opportunity to cultivate talent in alignment with the company’s
                ethos. At Recruit Today, our permanent recruitment strategy is
                focused on understanding your long-term visions. We aim to
                connect you with candidates who not only possess the required
                skills but also resonate with your organizational values and
                aspirations. The essence of permanent hiring lies in fostering a
                cohesive company culture, building lasting relationships, and
                supporting the continuous development of your employees.
              </p>
            </div>
          </div>
          <div className="lg:w-2/4 bg-permanent-recruitment bg-cover bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-auto  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
        </div>
      </div>
      {/* Permanent Recruitment */}

      {/* Recruitment Process Outsourcing (RPO)*/}
      <div id="Recruitment-process" className="section-y-pad">
        <div className="flex lg:flex-row flex-col lg:gap-6 gap-5">
          <div className="lg:w-2/4 bg-executive-recruitment bg-contain bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-[auto]  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
          <div className="lg:w-2/4 w-full flex items-center justify-start section-y-pad lg:px-0 section-x-pad">
            <div  className="f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">
                Recruitment Process Outsourcing (RPO)
              </h2>
              <p className="para">
                Recruit Today’s Recruitment Process Outsourcing (RPO)
                revolutionizes talent sourcing. Our RPO service is designed to
                address the challenges businesses face when expanding their
                recruitment efforts. By integrating our experienced recruiters
                into your team, you gain the flexibility to quickly adapt to
                changing hiring needs. RPO not only accelerates the hiring
                process but also reduces costs and shortens recruitment
                timelines, according to industry data. Through our RPO approach,
                you can enhance your employer brand, improve the candidate
                experience, and adhere to best recruitment practices. Our
                skilled recruiters combine industry knowledge with agility,
                aligning with your company’s culture and attracting candidates
                who fit your needs. With Recruit Today’s RPO expertise, you’re
                not just outsourcing; you’re partnering with a strategic ally
                focused on accelerating your growth through exceptional talent
                management.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {/* Recruitment Process Outsourcing (RPO)*/}

      {/*Contract-based Recruitment*/}
      <div id="Contract_based" className="section-y-pad">
        <div className="flex lg:flex-row flex-col-reverse lg:gap-6 gap-5">
          <div className="lg:w-2/4 w-full flex items-center justify-end section-y-pad lg:px-0 section-x-pad">
            <div  className="f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">Contract-based Recruitment</h2>
              <p className="para">
                Craft teams not just on paper, but in spirit and synergy.
                Whether your project requires a small team of five or a larger
                group of fifty, Recruit Today ensures each member aligns in both
                skills and ethos for optimal cohesion and performance. Our
                project-based recruitment focuses not on numbers, but on
                harmony, alignment, and collective excellence, tailored
                specifically for your project's success.
              </p>
            </div>
          </div>
          <div className="lg:w-2/4 bg-RPO bg-cover bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-auto  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
        </div>
      </div>
      {/*Contract-based Recruitment*/}

      {/* HR Services */}
      {/* <div className="section-y-pad">
        <div className="flex lg:flex-row flex-col lg:gap-6 gap-5">
          <div className="lg:w-2/4 bg-HR-services bg-cover bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-auto  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
          <div className="lg:w-2/4 w-full flex items-center justify-start section-y-pad lg:px-0 section-x-pad">
            <div className="f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">Contingent Recruitment</h2>
              <p className="para">
                In an ever-evolving business landscape, adaptability reigns
                supreme. Many of our clients lean on contingent workforce
                solutions to tackle project-centric needs, navigate seasonal
                fluxes, or bridge unforeseen talent gaps. Contingent staffing
                empowers businesses to modulate their workforce in tandem with
                real-time demands, striking a balance between agility and cost
                efficiency. By choosing emergiTEL as your contingent staffing
                ally, you’re unlocking a reservoir of skilled professionals
                primed to dovetail into your initiatives, safeguarding both
                momentum and effectiveness.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* HR Services */}

      {/* Project-based Recruitment */}
      {/* <div className="section-y-pad">
        <div className="flex lg:flex-row flex-col-reverse lg:gap-6 gap-5">
          <div className="lg:w-2/4 w-full flex items-center justify-end section-y-pad lg:px-0 section-x-pad">
            <div className="f-col lg:gap-2 gap-1 lg:w-[650px]">
              <h2 className="section-heading">Contingent Recruitment</h2>
              <p className="para">
                In an ever-evolving business landscape, adaptability reigns
                supreme. Many of our clients lean on contingent workforce
                solutions to tackle project-centric needs, navigate seasonal
                fluxes, or bridge unforeseen talent gaps. Contingent staffing
                empowers businesses to modulate their workforce in tandem with
                real-time demands, striking a balance between agility and cost
                efficiency. By choosing emergiTEL as your contingent staffing
                ally, you’re unlocking a reservoir of skilled professionals
                primed to dovetail into your initiatives, safeguarding both
                momentum and effectiveness.
              </p>
            </div>
          </div>
          <div className="lg:w-2/4 bg-project-based-recruitment bg-cover bg-no-repeat bg-center relative before:absolute before:content-[''] lg:h-auto  h-[350px] lg:mx-0 section-x-margin before:bg-secondary-transparent before:w-full before:h-full top-0 left-0 right-0 bottom-0"></div>
        </div>
      </div> */}
      {/* Project-based Recruitment */}
    </>
  );
};

export default Services;
