import React, { useEffect } from "react";
import { scrollToTop } from "../../utils/helper";

const TermsAndCondition = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="section-y-pad bg-white">
      <div className="container section-x-pad ">
        <h2 className="lg:text-3xl md:text-2xl text-xl md:font-bold font-semibold text-primary lg:border-b-[3px] border-b-2 border-primary pb-4">
          Terms and Conditions
        </h2>
        <div className="f-col gap pt-8">
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>1).</b> <span className="underline">Acceptance of Terms</span>
            </h4>
            <p className="term-text">
              By engaging with Recruit Today for any of the services, the client
              candidate agrees to abide by these terms and conditions.
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>2).</b>{" "}
              <span className="underline">
                Payment of the Service Fee and Validating the Guarantee
              </span>
            </h4>
            <p className="term-text">
              <b>a).</b> 50% advance payment is required to initiate the
              services, full payment is due within 10 days. If payment is not
              received in full by the Agency within the above specified time,
              the Agency reserves the right to halt the services
            </p>
            <p className="term-text">
              <b>b).</b> In the event that the client secures services from
              another provider or obtains employment through a previous
              application during our contractual period, thus making our
              services redundant, the agency will not be entitled to a refund
              for the fees of the current month
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>3).</b> <span className="underline">Process</span>
            </h4>
            <p className="term-text">
              Recruit Today commits to sending relevant applications in bulk on
              each portal on a daily basis. This approach is adopted because the
              job application process can be time-consuming and requires
              patience. Therefore, we kindly request that the candidate trust
              the process as we manage their applications efficiently and
              effectively. This strategy allows for a systematic and organized
              approach to maximize the candidate's chances of success in their
              job search endeavours.
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>4).</b>{" "}
              <span className="underline">
                Incorrect applications, Missing days and Emergencies
              </span>
            </h4>
            <p className="term-text">
              In the event of incorrect applications or unforeseen absences of
              the recruiter due to emergencies, the agency may be eligible to
              compensate for any wasted days. This compensation may involve
              extending the duration of the service period to include all the
              missed days, ensuring that the agreed-upon services are fulfilled
              despite any interruptions
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>5).</b>{" "}
              <span className="underline">
                LinkedIn and Job Board Management
              </span>
            </h4>
            <p className="term-text">
              Recruit Today will manage your LinkedIn account and job boards
              specific to your field or region. To facilitate this, the agency
              will require access to a Gmail account, either a previous one or a
              new one created for this purpose, to sign up for the job boards.
              By agreeing to this arrangement, you consent to provide the
              necessary access to enable Recruit Today to effectively manage
              your job search efforts on these platforms
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>6).</b>{" "}
              <span className="underline">Interview Preparation</span>
            </h4>
            <p className="term-text">
              The interview preparation services will be exclusively provided
              for 1:1 interviews or any virtual interview scenarios. The agency
              will not be responsible for preparing the candidate for webinars,
              HireVue sessions, or any other assessment formats. Candidates
              should be aware that the scope of the preparation services does
              not extend to these alternative formats and should make
              appropriate arrangements for such events independently
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>7).</b> <span className="underline">Confidentiality</span>
            </h4>
            <p className="term-text">
              Recruit Today respects your privacy and commits to maintaining the
              confidentiality of your information.
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>8).</b>{" "}
              <span className="underline">Limitation of Liability </span>
            </h4>
            <p className="term-text">
              Recruit Today will make every effort to provide high-quality
              services. However, the company is not responsible for the final
              outcome, such as job placement, which depends on various factors
              beyond the company's control. The agency only commits a fair and
              transparent job application Process.
            </p>
          </div>
          <div className="f-col gap">
            <h4 className="term-heading">
              <b>9).</b> <span className="underline">Amendments</span>
            </h4>
            <p className="term-text">
              Recruit Today reserves the right to modify these terms and
              conditions at any time. You will be notified of any changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
