import axios from "axios";
import React, { createContext, useContext } from "react";
import { apiUrl } from "../constant";
export const appContext = createContext();

export const AppProvider = ({ children }) => {
  const apply = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/api/client-info/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      return response;
    } catch (error) {
      return error;
    }
  };
  const applyForAll = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/api/client-info/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      return response;
    } catch (error) {
      return error;
    }
  };

  const hireEmployee = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/hiring/hire-talent/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      return response;
    } catch (error) {
      return error;
    }
  };

  const resumeLinkndinOptimization = async (data) => {
    try {
      const response = await axios.post(
        `${apiUrl}/hiring/resume-linkedin-optimization/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      return error;
    }
  };

  return (
    <appContext.Provider
      value={{
        apply,
        applyForAll,
        resumeLinkndinOptimization,
        hireEmployee,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(appContext);
};
