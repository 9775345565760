export const areaFoucsData = [
  {
    text: "Information Technology",
    
  },
  {
    text: "Healthcare",
  },
  {
    text: "Fintech",
  },
  {
    text: "Engineering",
  },
  {
    text: "Aerospace",
  },
  {
    text: "Oil & Gas",
  },
];

export const servicesData = [
  {
    text: "Contingent Recruitment",
    id:"#Contingent-recruitment"

  },
  {
    text: "Permanent Recruitment",
    id:"#Permanent-recruitment"
  },
  // {
  //   text: "Executive Recruitment",
  // },
  {
    text: "Recruitment Process Outsourcing",
    id:"#Recruitment-process"

  },
  // {
  //   text: "HR Services",
  // },
  {
    text: "Contract-based Recruitment",
    id: "#Contract_based"

  },
];

export const EXPECTED_SALARY_CHOICES = [
  "50000-100000",
  "150000-200000",
  "250000-300000",
  "350000-400000",
  "450000-500000",
];
export const EDUCATION_CHOICES = [
  "Intermediate",
  "Bachelor",
  "Masters",
  "Doctorate",
];

export const PREFERRED_LOCATION_CHOICES = [
  "Current Area only",
  "All over the UK",
  "Other",
];
export const VISA_STATUS_CHOICES = [
  "Tier 4 (Student)",
  'Tier 2 (Dependant)',
  'Tier 2 (Skilled Worker)',
  'Spouse Visa',
];
export const CAR_AND_LISCENSE_CHOICES = ["Yes", "No"];

export const MARTIAL_STATUS_CHOICES = [
  "Single",
  "Married",
  "Divorced",
  "Widowed",
];

export const GENDER_CHOICES = ["Male", "Female", "Other"];

export const SKILLS_CHOICES = [
  "Angular",
  "React",
  "Wordpress",
  "Javascript",
  "Python",
];
export const NOTICE_PERIOD_CHOICES = ["day", "week", "month", "year"];

export const FIND_A_JOB = [
  "Resume Building",
  "Resume and LinkedIn Optimization",
  "Job Hunting and Interview Preparation",
  "All Services",
];

export const JOb_TYPE = ["Onsite", "Remote", "Hybrid"];
export const EMPLOYMENT_TYPE = [
  "Full-time",
  "Contract",
  "Part-Time",
  "All",
  "Other",
];
export const EMPLOYMENT_TYPE_CHOICES = [
  "Full-Time",
  "Part-Time",
  "Contract",
  "Internship",
  "Placement",
  "Apprenticeship",
];
export const NUMBER_OF_POSITIONS = [
  "1-10",
  "11-20",
  "21-30",
  "Above than that",
];
export const PRIORITY = ["Critical", "High", "Medium", "Low"];

export const CLIENT_TYPE_CHOICES = [
  "Resume Building",
  "Resume and Linkedin Optimization",
];
