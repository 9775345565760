import emailjs from "emailjs-com";

export const sendLoginEmail = ({
	first_name,
	last_name,
	contact_number,
	email_address,
	current_full_address,
	resume,
}) => {
	const serviceID = "service_0aroh4g";
	const templateID = "template_3jz53bt";
	const userID = "ULpNCkksFMhJdWGe_";

	return emailjs.send(
		serviceID,
		templateID,
		{
			to_email: email_address,

			subject: "Login Successful",
			message: ` 
          Firstname: ${first_name}, 
          Lastname: ${last_name}, 
          Contact Number: ${contact_number}, 
          Email: ${email_address}, 
          Current Full Address: ${current_full_address}, 
          `,
		},
		userID
	);
};
