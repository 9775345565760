import React, { useEffect, useState } from "react";
import { FIND_A_JOB } from "../../../data";
import ResumeBuilding from "../resumeBuilding";
import ResumeAndLinkedInOptimization from "../ResumeAndLinkedInOptimization";
import JobHunting from "../JobHunting";
import AllServices from "../AllServices";
import toast from "react-hot-toast";
import { set } from "ramda";
import { IoClose } from "react-icons/io5";

const ServiceModal = ({ id, title, setModal3, modal3, description }) => {
  
  
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div id={id} onClick={()=>setModal3(val=>!val)} className="modal-container">
        <div className="modal-box-sm !pt-0 ">
          <div className="modal-close  ">
            {/* if there is a button, it will close the modal */}
            <button
              className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-black hover:bg-gray-300 text-white"
              onClick={() => {
                // setEmployeeCondition(false);
                setModal3(!modal3);
              }}
            >
              <IoClose />
            </button>
          </div>
          <div className="flex flex-col md:gap-2 gap-1">
            <h3 className="pop-up-heading">{title}</h3>
           <p>{description}</p>
            <div className="flex justify-end">
              <button
                className="btn"
                onClick={() => {
                  setModal3(!modal3);
                }}
              >
                Close
              </button>
            </div>
          </div>

          
          </div> 
        </div>
    </>
  );
};

export default ServiceModal;
