import React, { useEffect, useState } from "react";
import { FIND_A_JOB } from "../../../data";
import ResumeBuilding from "../resumeBuilding";
import ResumeAndLinkedInOptimization from "../ResumeAndLinkedInOptimization";
import JobHunting from "../JobHunting";
import AllServices from "../AllServices";
import toast from "react-hot-toast";
import { set } from "ramda";
import { IoClose } from "react-icons/io5";

const Modal = ({ id, title, setModal, modal }) => {
	const [selectedJob, setSelectedJob] = useState("");
	const [resumeBuildingModal, setResumeBuildingModal] = useState(false);
	const [
		resumeAndLinkedInOptimizationModal,
		setResumeAndLinkedInOptimizationModal,
	] = useState(false);
	const [jobHuntingModal, setJobHuntingModal] = useState(false);
	const [allServicesModal, setAllServicesModal] = useState(false);
	const changeHandler = (e) => {
		const { value } = e.target;
		setSelectedJob(value);
		if (value === "Resume Building") {
			// document.getElementById("resumeBuilding").showModal();
			setResumeBuildingModal(!resumeBuildingModal);
		}
		if (value === "Resume and LinkedIn Optimization") {
			setResumeAndLinkedInOptimizationModal(
				!resumeAndLinkedInOptimizationModal
			);
			// document.getElementById("resume_and_linkedin_optimization").showModal();
		}
		if (value === "Job Hunting and Interview Preparation") {
			setJobHuntingModal(!jobHuntingModal);
			// document.getElementById("jobHunting").showModal();
		}
		if (value === "All Services") {
			setAllServicesModal(!allServicesModal);
			// document.getElementById("resumeBuilding").showModal();
			// document.getElementById("allServices").showModal();
		}
	};

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	return (
		<>
			<div
				onClick={() => setModal((val) => !val)}
				id={id}
				className="modal-container"
			>
				<div
					onClick={(e) => e.stopPropagation()}
					className="modal-box-sm !pt-0 "
				>
					<div className="modal-close  ">
						{/* if there is a button, it will close the modal */}
						<button
							className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-black hover:bg-gray-300 text-white"
							onClick={() => {
								// setEmployeeCondition(false);
								setModal(!modal);
							}}
						>
							<IoClose />
						</button>
					</div>
					<div className="flex flex-col md:gap-2 gap-1">
						<h3 className="pop-up-heading">{title}</h3>
						<select
							onClick={(e) => e.stopPropagation()}
							className="md:p-[[12px] p-[10px] md:rounded-md rounded-sm focus:ring-4 focus:ring-offset-2  focus-within:ring-4 focus-within:border-primary focus:ring-secondary w-full border-primary border-[3px] bg-transparent outline-nonen active:outline-none text-black md:text-lg text-base md:font-semibold font-medium "
							value={selectedJob}
							onChange={changeHandler}
						>
							<option value={null}>--select--</option>
							{FIND_A_JOB.map((item, index) => (
								<option key={index} value={item}>
									{item}
								</option>
							))}
						</select>
						<div className="flex justify-end">
							<button
								className="btn"
								onClick={() => {
									setSelectedJob("");
									setModal(!modal);
								}}
							>
								Close
							</button>
						</div>
					</div>

					{/* <div className="modal-box bg-white max-w-[530px] w-full">
            <div className="flex flex-col md:gap-2 gap-1">
              <h3 className="pop-up-heading">{title}</h3>
              <select
                className="select focus:ring-4 focus:ring-offset-2  focus-within:ring-4 focus-within:border-primary focus:ring-secondary w-full border-primary border-[3px] bg-transparent text-black md:text-lg text-base md:font-semibold font-medium "
                value={selectedJob}
                onChange={changeHandler}
              >
                <option value={null}>--select--</option>
                {FIND_A_JOB.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div className="modal-action">
  
                <button
                  className="btn"
                  onClick={() => {
                    document.getElementById(id).close();

                    setSelectedJob("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div> */}
				</div>

				{/* resumeBuilding */}
				{resumeBuildingModal && (
					<ResumeBuilding
						id="resumeBuilding"
						title="Resume Building"
						resumeBuildingModal={resumeBuildingModal}
						setResumeBuildingModal={setResumeBuildingModal}
					/>
				)}
				{/* resumeBuilding */}

				{/* resume_and_linkedin_optimization */}

				{resumeAndLinkedInOptimizationModal && (
					<ResumeAndLinkedInOptimization
						id="resume_and_linkedin_optimization"
						title="Resume and LinkedIn Optimization"
						resumeAndLinkedInOptimizationModal={
							resumeAndLinkedInOptimizationModal
						}
						setResumeAndLinkedInOptimizationModal={
							setResumeAndLinkedInOptimizationModal
						}
					/>
				)}

				{/* resume_and_linkedin_optimization */}

				{/* job Hunting and Interview Preparations  */}

				{jobHuntingModal && (
					<JobHunting
						id="jobHunting"
						title="job Hunting and Interview Preparations"
						jobHuntingModal={jobHuntingModal}
						setJobHuntingModal={setJobHuntingModal}
					/>
				)}

				{/* job Hunting and Interview Preparations  */}

				{/* All Services */}
				{allServicesModal && (
					<AllServices
						id="allServices"
						title="All Services"
						allServicesModal={allServicesModal}
						setAllServicesModal={setAllServicesModal}
					/>
				)}
				{/* All Services */}
			</div>
		</>
	);
};

export default Modal;
