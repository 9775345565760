import React, { useEffect, useState } from "react";
import FindEmployee from "../../components/modals/findEmployee";
import { IoClose } from "react-icons/io5";
import Modal from "../../components/modals/FindAJobModal";
import RecruitServicesComp from "../../components/recruiteServicesComp";

const RegisterModal = ({ title, setRegisterModal, registerModal }) => {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div className="modal-container">
        <div className="modal-box-sm !pt-0">
          <div className="modal-close  ">
            {/* if there is a button, it will close the modal */}
            <button
              className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-black hover:bg-gray-300 text-white"
              onClick={() => {
                // setEmployeeCondition(false);
                setRegisterModal(!registerModal);
              }}
            >
              <IoClose />
            </button>
          </div>
          <div className="flex flex-col md:gap-2 gap-1">
            <h3 className="pop-up-heading">{title}</h3>
       
            <RecruitServicesComp modal={modal} setModal={setModal} modal2={modal2} setModal2={setModal2} />
            <div className="flex justify-end">
              <button
                className="btn"
                onClick={() => {
                  setRegisterModal(!registerModal);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          id="find_job"
          title="Find a Job!"
          modal={modal}
          setModal={setModal}
        />
      )}
      {/*  find_job*/}

      {modal2 && (
        <FindEmployee
          id="find_employee"
          title="Find an Employee!"
          modal2={modal2}
          setModal2={setModal2}
        />
      )}
    </>
  );
};

export default RegisterModal;
