import React, { useEffect, useState } from "react";

import { sendLoginEmail } from "../../../utilses/email"
import {
  EMPLOYMENT_TYPE,
  JOb_TYPE,
  NUMBER_OF_POSITIONS,
  PRIORITY,
} from "../../../data";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import TermAndConditions from "../termAndConditions";
import { useAppContext } from "../../../context/appContext";
import { in200s } from "../../../helper";

const FindEmployee = ({ id, title, modal2, setModal2 }) => {
  const [employeeCondition, setEmployeeCondition] = useState(false);
  const [conditionPopup, setConditionPopup] = useState(false);
  const { hireEmployee } = useAppContext();
  const [laoding, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState({
    first_name: "",
    employer_number: "",
    business_email: "",
    company_name: "",
    company_location: "",
    zip_code: "",
    job_title: "",
    job_type: "",
    employment_type: "",
    number_of_positions: "",
    priority: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!employeeCondition) {
      toast.error("Please agree to the terms and conditions");
      return;
    }
    if (
      !inputValue.first_name ||
      !inputValue.employer_number ||
      !inputValue.business_email ||
      !inputValue.company_name ||
      !inputValue.company_location ||
      !inputValue.zip_code ||
      !inputValue.job_title ||
      !inputValue.job_type ||
      !inputValue.employment_type ||
      !inputValue.number_of_positions ||
      !inputValue.priority
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    await sendLoginEmail(inputValue)
    .then(response => console.log('Email sent successfully:', response))
    .catch(error => console.error('Error sending email:', error));


    setLoading(true);
    const data = {
      first_name: inputValue.first_name,
      employer_number: inputValue.employer_number,
      business_email: inputValue.business_email,
      company_name: inputValue.company_name,
      company_location: inputValue.company_location,
      zip_code: inputValue.zip_code,
      job_title: inputValue.job_title,
      job_type: inputValue.job_type,
      employment_type: inputValue.employment_type,
      number_of_positions: inputValue.number_of_positions,
      priority: inputValue.priority,
    };
    const response = await hireEmployee(data);
    console.log(response);
    if (in200s(response.status)) {
      toast.success("Employee hired successfully");
      setEmployeeCondition(false);
      setModal2(!modal2);
    } else {
      toast.error("Failed to hire employee");
    }

    setLoading(false);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      {/* find_Employee */}
      <div onClick={()=>setModal2(val=>!val)} id={id} className="modal-container">
        <div onClick={(e) => e.stopPropagation()} className="modal-box-lg !pt-0 ">
          <div className="modal-close ">
            {/* if there is a button, it will close the modal */}
            <button
              className="md:p-2  p-1 md:text-2xl text-xl outline-none border-none md:rounded-md rounded-sm mt-1  bg-black hover:bg-gray-300 text-white"
              onClick={() => {
                setEmployeeCondition(false);
                setModal2(!modal2);
              }}
            >
              <IoClose />
            </button>
          </div>
          <div className="flex flex-col md:gap-2 gap-1">
            <h3 className="pop-up-heading">{title}</h3>

            <form
              className="flex md:gap-1 gap-1 flex-col"
              onSubmit={submitHandler}
            >
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-1 gap-0.1">
                {/* Employer Name */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="first_name" className="label-text">
                    Employer Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    value={inputValue.first_name}
                    onChange={handleChange}
                    name="first_name"
                    className="input-field"
                  />
                </div>
                {/* Employer Name */}

                {/*Employer Number */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="employer_number" className="label-text">
                    Employer Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="employer_number"
                    value={inputValue.employer_number}
                    onChange={handleChange}
                    name="employer_number"
                    className="input-field"
                  />
                </div>
                {/* Employer Number */}

                {/* Business Email  */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="business_email" className="label-text">
                    Business Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="business_email"
                    value={inputValue.business_email}
                    onChange={handleChange}
                    name="business_email"
                    className="input-field"
                  />
                </div>
                {/* Business Email */}

                {/* company Name  */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="company_name" className="label-text">
                    company Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="company_name"
                    value={inputValue.company_name}
                    onChange={handleChange}
                    name="company_name"
                    className="input-field"
                  />
                </div>
                {/* company Name  */}

                {/* Company Location  */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="company_location" className="label-text">
                    Company Location <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="company_location"
                    value={inputValue.company_location}
                    onChange={handleChange}
                    name="company_location"
                    className="input-field"
                  />
                </div>
                {/* Company Location */}

                {/*Zip Code  */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="zip_code " className="label-text">
                    Zip Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="zip_code"
                    value={inputValue.zip_code}
                    onChange={handleChange}
                    name="zip_code"
                    className="input-field"
                  />
                </div>
                {/* Zip Code */}

                {/*Job Title */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="job_title" className="label-text">
                    Job Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="job_title"
                    value={inputValue.job_title}
                    onChange={handleChange}
                    name="job_title"
                    className="input-field"
                  />
                </div>
                {/* Job Title*/}

                {/*Job Type  */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="job_type" className="label-text">
                    Job Type <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="job_type"
                    id="job_type"
                    className="input-field"
                    value={inputValue.job_type}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>

                    {JOb_TYPE.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Job Type*/}

                {/*Employment Type */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="employment_type " className="label-text">
                    Employment Type<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="employment_type"
                    id="employment_type"
                    className="input-field"
                    value={inputValue.employment_type}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>

                    {EMPLOYMENT_TYPE.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {/*Employment Type*/}

                {/*Number of Positions */}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="number_of_positions " className="label-text">
                    Number of Positions <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="number_of_positions"
                    id="number_of_positions"
                    className="input-field"
                    value={inputValue.number_of_positions}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>

                    {NUMBER_OF_POSITIONS.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {/*Number of Positions */}

                {/*Priority*/}
                <div className="f-col gap-0.8 md:gap-0.1">
                  <label htmlFor="priority" className="label-text">
                    Priority <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="priority"
                    id="priority"
                    className="input-field"
                    value={inputValue.priority}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>

                    {PRIORITY.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {/*Priority*/}
              </div>
              <div className="flex items-center md:gap-0.8 gap-0.5">
                <input
                  type="checkbox"
                  className="checkbox border-[1px] focus:ring-1 focsu:ring-primary checkbox-xs rounded-sm border-primary [--chkbg:theme('colors.primary')]"
                  name="termAndConditions"
                  id="termAndConditions"
                  checked={employeeCondition}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmployeeCondition(!employeeCondition);

                      setConditionPopup(!conditionPopup);
                    }
                    setEmployeeCondition(!employeeCondition);
                  }}
                />
                <label
                  htmlFor="termAndConditions"
                  className="text-sm text-gray-500"
                >
                  I agree to the{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      setEmployeeCondition(true);
                      setConditionPopup(!conditionPopup);
                    }}
                  >
                    terms and conditions
                  </span>
                </label>
              </div>
              <div className="flex items-center justify-center">
                <button type="submit" className="btn">
                  {laoding ? "submiting..." : "submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {conditionPopup && (
        <TermAndConditions
          id="termAndConditions"
          title="Terms and Conditions"
          conditionPopup={conditionPopup}
          setConditionPopup={setConditionPopup}
        />
      )}

      {/* find_Employee */}
    </>
  );
};

export default FindEmployee;
