import React, { useState } from "react";

const RecruitServicesComp = ({ modal, setModal, modal2, setModal2 }) => {
	return (
		<div className="grid md:grid-cols-2 grid-cols-1 place-items-center gap-4 w-full">
			<div class="bg-secondary rounded-md hover:bg-primary w-full  md:cursor-pointer cursor-auto transition-all  shadow-sm shadow-secondary md:p-[40px] p-[30px]  flex flex-col items-center justify-center lg:gap-2 md:gap-1 gap-0.8">
				<h3 class="text-white text-2xl px-[40px] font-bold text-center leading-none capitalize">
					Get Hired
				</h3>
				<p className="md:text-base text-sm leading-6 tracking-wide text-white text-center ">
					Your Dream Job Is Just A Click Away
				</p>
				<button
					class="mt-auto w-full md:text-base text-sm md:font-semibold font-normal text-white bg-transparent rounded border border-white px-6 py-2 transition duration-300"
					onClick={() =>
						// document.getElementById("find_job").showModal()
						setModal(!modal)
					}
				>
					Find a Job
				</button>
			</div>
			<div class="bg-primary rounded-md hover:bg-secondary w-full  md:cursor-pointer cursor-auto transition-all  shadow-sm shadow-secondary md:p-[40px] p-[30px]  flex flex-col items-center justify-center lg:gap-2 md:gap-1 gap-0.8">
				<h3 class="text-white text-2xl px-[38px] font-bold text-center leading-none capitalize">
					Hire Talent
				</h3>
				<p className="md:text-base text-sm leading-6 tracking-wide text-white text-center">
					Find The Right Person For The Job
				</p>
				<button
					class="mt-auto w-full md:text-base text-sm md:font-semibold font-normal text-white bg-transparent rounded border border-white px-6 py-2 transition duration-300"
					onClick={() =>
						// document.getElementById("find_employee").showModal()
						setModal2(!modal2)
					}
				>
					Find an Employee
				</button>
			</div>
		</div>
	);
};

export default RecruitServicesComp;
